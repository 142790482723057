<template>
  <div class="manage-hr-companies">
      
      <div class="hr-companies">
        <div class="search">
            <el-input v-model="search" placeholder="חפש חברת כח אדם">
                <template #prefix>
                    <i class="el-input__icon el-icon-search"></i>
                </template>
            </el-input>
          </div>
          <div class="add-hr-company">
              <el-button @click="handle_add_new_company" type="success" icon="el-icon-plus" circle></el-button>
          </div>
        <template v-for="company in filter_by_search" :key="company">
            <div class="hr-company">
                <HrCompany @modify="modify_company($event)" @delete="delete_company($event)" :company_name="company" />
            </div>
        </template>
         
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import HrCompany from '../../../components/Admin/Shivuk_Rosman/hr_companies/HrCompany.vue'
import { computed, onMounted } from '@vue/runtime-core'
import {get_hr_companies_from_db,add_new_hr_company_to_db} from '../../../Methods/rosman_shivuk_funcs'
import Swal from 'sweetalert2'

export default {
    components:{HrCompany},
    setup(){
        const search = ref('')
        const companies = ref([])

        const handle_add_new_company = ()=>{
            Swal.fire({
                icon: 'question',
                title: 'שם חברת כוח אדם חדש',
                input: 'text',
                confirmButtonText: 'עדכן',
                cancelButtonText: 'בטל',
                showCancelButton: true,
                denyButtonText: 'בטל עדכון',
                showDenyButton: true
            }).then(async res=>{
                if(res.isConfirmed && res.value){
                    await add_new_hr_company_to_db(res.value)
                    companies.value.push(res.value)
                }
            })
        }
        const filter_by_search = ref(computed(()=>{
            if(search.value=='') return companies.value
            return companies.value.filter(company=>{
                if(company.includes(search.value))return company
            })
        }))
        
        const delete_company=(company_name)=>{
            const index = companies.value.findIndex(company=>company==company_name)
            if(index!=-1){
                companies.value.splice(index,1)
            }
        }
        const modify_company=(data)=>{
            const index = companies.value.findIndex(company=>company==data.old_company_name)
            if(index!=-1){
                companies.value[index]=data.new_company_name
            }
        }
        onMounted(async()=>{
            companies.value = await get_hr_companies_from_db()
        })

        return{delete_company,modify_company,handle_add_new_company,search,filter_by_search}
    }
}
</script>

<style scoped>
    .manage-hr-companies{
        width: 100%;
        height: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
    }
    .hr-companies{
        width: 375px;
        height: 100%;
        margin-bottom: 5px;
        text-align: center;
        overflow:hidden;
        overflow-y: auto;
    }
    .hr-company{
        margin-bottom: 5px;
        width: 100%;
        height: 50px;
    }
    .add-hr-company{
        width: 100%;
        height: 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .search{
        width: 100%;
        height: 40px;
    }
</style>